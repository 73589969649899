import { Style } from './style';
import { Select } from 'zent';
import { useIntl } from 'react-intl';
import React, { useState, useEffect, useMemo } from 'react';
import AddressForm from '../../../account/new-address/address-form';
import LocalStorage from 'utils/local-storage';
import { SAVE_USER_BILL_ADDRESS } from 'utils/local-storage/config';
import cn from 'classnames';
import AddressChangeDialog from '../address-form/address-change-dialog';
import AddressItem from '../address-item';
import userApi from 'api/user';
import AddressWarnTip from '../address-warn-tip';
import AddressEditDialog from '../address-form/address-edit-dialog';
import AddressInput from '../address-form/address-input';

const BillAddress = (props) => {
  const {
    locale,
    needShip,
    billAddressForm,
    // setBillAddressType,
    // sameAsShippingAddress,
    billAddressType,
    setBillAddressType,
    currentBillingAddress,
    setCurrentBillingAddress, // 选择已有地址
    setIsSaveBillAddress,
    billingAddressStatus,
  } = props;
  const intl = useIntl();
  const [isSave, setIsSave] = useState(true);

  // const [billAddressTypeValue, setBillAddressTypeValue] = useState(1); // 账单地址类型
  const [shippingAddress, setShippingAddress] = useState(null);
  const [visibleAddressDialog, setVisibleAddressDialog] = useState(false);
  const [editAddressVisible, setEditAddressVisible] = useState(false);

  const userInfo = window?.global_data?.userInfo;
  const [addressList, setAddressList] = useState([]);

  const showCallingTip = !currentBillingAddress?.callingCodes || !currentBillingAddress?.callingArea;

  const billAddressTypeList = useMemo(() => {
    const list = [
      {
        value: 0,
        text: intl.formatMessage({ defaultMessage: '使用其它地址', id: '03831da55b7a474da823b9e244b9b29d' }),
      },
    ];
    if (userInfo?.customerId && addressList?.length > 0) {
      list.unshift({
        value: 2,
        text: intl.formatMessage({ defaultMessage: '选择已有地址', id: '43054bbee8654b4f8a06abd920fbaca6' }),
      });
    }
    if (needShip) {
      list.unshift({
        value: 1,
        text: intl.formatMessage({ defaultMessage: '同收货地址', id: '16874e0163f743eabde1d710596f3b3f' }),
      });
    }
    return list;
  }, [needShip, addressList]);

  const initUserAddress = () => {
    // 读取缓存地址
    const shippingAddress = LocalStorage.get(SAVE_USER_BILL_ADDRESS);
    if (shippingAddress) {
      setShippingAddress(shippingAddress);
    }
    if (!userInfo?.customerId) {
      return;
    }
    return userApi
      .getAddressesByCustomerId({
        customerId: userInfo?.customerId,
        kdtId: userInfo?.custokdtIdmerId,
      })
      .then((list) => {
        if (list.length > 0) {
          setAddressList(list);
        } else {
          setBillAddressType(0);
        }
      });
  };

  const onSelect = (e) => {
    const type = e.target.value;
    setBillAddressType(type);
  };

  const onchangeIsSave = (checked) => {
    setIsSave(checked);
    setIsSaveBillAddress(checked);
  };

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const changeOpenStatus = () => {
    setIsSelectOpen(false);
  };
  useEffect(() => {
    initUserAddress();
    document.body.addEventListener('click', changeOpenStatus, true);
    return () => {
      document.body.removeEventListener('click', changeOpenStatus);
    };
  }, []);

  return (
    <Style>
      <div className='bill-address-title'>
        {intl.formatMessage({ defaultMessage: '帐单地址', id: 'order.pay.order_address' })}
      </div>
      <div className='select-wrapper'>
        <div className='select-wrapper-body'>
          <Select
            data={billAddressTypeList}
            value={billAddressType}
            onChange={onSelect}
            autoWidth
            onOpen={() => {
              setIsSelectOpen(true);
            }}
          />
          <div className={cn('hunt-select-switch', `hunt-select-switch-is-open-${isSelectOpen}`)}>
            <svg className={cn('icon icon-switch')} width='16' height='16' aria-hidden='true'>
              <use xlinkHref='#iconic-xiala1' />
            </svg>
          </div>
        </div>

        <div className='pay-address-wrapper'>
          {billAddressType === 0 && (
            <>
              {/* <AddressForm
                                intl={intl}
                                locale={locale}
                                isBillAddress
                                billAddressForm={billAddressForm}
                                shippingAddress={shippingAddress}
                            /> */}
              <AddressInput
                intl={intl}
                className='billing-address-input'
                form={billAddressForm}
                currentAddress={shippingAddress}
                setChecked={onchangeIsSave}
                checked={isSave}
                fromType='bill-address'
              />
              {/* <div className='save-as-default-wrapper'>
                                <Checkbox checked={isSave} onChange={onchangeIsSave}>
                                    {intl.formatMessage({
                                        defaultMessage: '保存地址下次使用',
                                        id: '46a62baa20bd4a5f8c5d6f63f2cc2af6',
                                    })}
                                </Checkbox>
                            </div> */}
            </>
          )}
          {billAddressType === 2 && (
            <>
              {currentBillingAddress ? (
                <div className='bill-address-card'>
                  <AddressItem
                    address={currentBillingAddress}
                    isLogin={!!userInfo?.customerId}
                    onClick={() => setVisibleAddressDialog(true)}
                    isCurrent
                    intl={intl}
                    setEditAddressVisible={setVisibleAddressDialog}
                    icon='#iconic-xiala'
                    extendTip={
                      showCallingTip ? (
                        <AddressWarnTip
                          text={intl.formatMessage({
                            id: '489d092d981e4ae2a0ce551d473e8a45',
                            defaultMessage: '区号未填写，请补充',
                          })}
                          onClick={() => {
                            setEditAddressVisible(true);
                          }}
                        />
                      ) : (
                        ''
                      )
                    }
                  />
                </div>
              ) : (
                <div className='choose-address-btn' onClick={() => setVisibleAddressDialog(true)}>
                  {billingAddressStatus ? (
                    <span>
                      {intl.formatMessage({ id: '61fa7eb0bd954a928477bed8bd756485', defaultMessage: '选择地址' })}
                    </span>
                  ) : (
                    <span className='error-status'>
                      {intl.formatMessage({ id: '305de39ae5bd4fb6bbb1776663cdd928', defaultMessage: '请选择地址' })}
                    </span>
                  )}
                  <svg className='icon icon-arrow-right' aria-hidden='true'>
                    <use xlinkHref='#iconic-xiala' />
                  </svg>
                </div>
              )}
              <AddressChangeDialog
                intl={intl}
                className='address-select-dialog'
                visible={visibleAddressDialog}
                currentAddress={currentBillingAddress}
                addressList={addressList}
                onClose={() => {
                  setVisibleAddressDialog(false);
                }}
                onRefresh={initUserAddress}
                onConfirm={(address) => setCurrentBillingAddress(address)}
                canEdit={false}
                notFullDisabled
                // {...addressProps}
              />
            </>
          )}
        </div>
      </div>
    </Style>
  );
};

export default BillAddress;
