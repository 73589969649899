import React, { useState, useEffect, memo } from 'react';
import copy from 'copy-to-clipboard';
import cn from 'classnames';
import { Icon } from 'components/base';
import Style from './style';
import { LocationDialog } from 'components/page/goods-detail/location-info';
import { formatLocationText } from 'utils/global';
import shopApi from 'api/shop';
import { Notify } from 'zent';

const OrderLocation = props => {
  const {
    className,
    $fm,
  } = props;

  const [visibleDialog, setVisibleDialog] = useState(false);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    shopApi.bulkGetLocations().then((res) => {
      setLocationList(res);
    });
  }, []);

  const onCopy = (text) => {
    copy(text);
    Notify.success($fm('store.template.copy_success', '复制成功！'));
  }
  
  return (
    <Style className={cn(className)}>
      <div className='title'>
        <div>{$fm('04fbeffc1db64a3890f98799159d37ff', '适用门店({num})', { num: locationList.length })}</div>
        {
          locationList.length > 1 &&
          <div className='view-all-btn' onClick={() => setVisibleDialog(true)}>
            <span>{$fm('design.preview_all', '查看全部')}</span>
            <Icon name='iconic-xiala' />
          </div>
        }
      </div>
      {locationList.length > 0 && (
        <div className='location-card'>
          <div className='location-card__name'>{locationList[0].name}</div>
          <div className='location-card__line-item'>
            <span>{formatLocationText(locationList[0])}</span>
            <Icon onClick={() => onCopy(formatLocationText(locationList[0]))} name='iconic-fuzhi' />
          </div>
          <div className='location-card__line-item'>
            <span>{locationList[0].phone}</span>
            <a href={`tel:${locationList[0].phone}`}>
              <Icon name='icona-01jichu_lianxidianhua' />
            </a>
          </div>
        </div>
      )}
      <LocationDialog onClose={() => setVisibleDialog(false)} $fm={$fm} visibleDialog={visibleDialog} locationList={locationList} />
    </Style>
  )
}

export default memo(OrderLocation);