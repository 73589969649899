import Styled from 'styled-components';

export default Styled.div`
  .title {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    .view-all-btn {
      display: flex;
      align-items: center;
      color: var(--color_button);
      gap: 4px;
      font-weight: 400;
      cursor: pointer;
      svg {
        flex-shrink: 0;
        fill: var(--color_button);
        width: 12px;
        height: 12px;
      }
    }
  }
  .location-card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__name {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #15161B;
    }
    &__line-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      font-size: 12px;
      line-height: 18px;
      color: rgba(var(--color_body_text_rgb), 0.6);
      svg {
        flex-shrink: 0;
        align-self: flex-start;
        fill: var(--color_button);
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }
`;
