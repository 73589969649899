import React, { useMemo } from 'react';

export const useAgreement = (props) => {
  const {
    $fm,
    checkoutSetting,
    needDataAgreement,
  } = props

  const showUserAgreement = (e) => {
    e.preventDefault();
    window.open('/policy/Terms-Of-Use');
  }
  const showDataAgreement = (e) => {
    e.preventDefault();
    window.open('/policy/Policy-Data');
  }

  // 是否需要同意勾选协议
  const {
    needAgreement,
    agreementEle,
    agreementText,
  } = useMemo(() => {
    if (!needDataAgreement && checkoutSetting?.requiresAgreement != 1) {
      return {
        needAgreement: false,
        agreementEle: '',
        agreementText: '',
      }
    };
    const isRequiresAgreement = checkoutSetting?.requiresAgreement == 1;
    const userAgreementText = $fm('0d87d16421eb4809ac2bea2c993a9491', '《用户协议》');
    const dataAgreementText = $fm('e6cd3312be984034858eb7b513c87d44', '《数据授权确认书》');
    const addText = $fm('40854b3185ab4b838b3f3fa63c84038a', '和');
    const needAgreement = needDataAgreement || isRequiresAgreement;
    if (needDataAgreement && isRequiresAgreement) {
      return {
        needAgreement,
        agreementEle: (
          <>
            <span className='agreement-title' onClick={showUserAgreement}>{userAgreementText}</span>
            <span>{addText}</span>
            <span className='agreement-title' onClick={showDataAgreement}>{dataAgreementText}</span>
          </>
        ),
        agreementText: `${userAgreementText} ${addText} ${dataAgreementText}`,
      }
    }
    if (isRequiresAgreement) {
      return {
        needAgreement,
        agreementEle: <span className='agreement-title' onClick={showUserAgreement}>{userAgreementText}</span>,
        agreementText: userAgreementText,
      }
    } else {
      return {
        needAgreement,
        agreementEle: <span className='agreement-title' onClick={showDataAgreement}>{dataAgreementText}</span>,
        agreementText: dataAgreementText,
      }
    }
  }, [checkoutSetting, needDataAgreement]);

  return {
    needAgreement,
    agreementEle,
    agreementText,
  }
}