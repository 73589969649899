import React from 'react';
import { useIntl } from 'react-intl';
import { intlLiToYuan } from 'utils/money';
import { useI18n } from 'hook/intl';
import Style from './style';

export const TotalInfo = (props) => {
  const { currency, totalPrice, totalLineItemsPrice, totalDiscount, multiCurrencyInfo } = props;
  const intl = useIntl();
  const { $fm } = useI18n(intl);
  const discount = ((totalPrice / totalLineItemsPrice) * 10).toFixed(2);

  return (
    <Style className='pay-total'>
      {/* 小记 */}
      <div className='item-block'>
        <span className='main-title'>{$fm('order.address.total_text', '小计')}</span>
        <span className='sub-title total-goods-price'>
          {intlLiToYuan({
            currency,
            value: totalLineItemsPrice,
          })}
        </span>
      </div>

      {/* 展示非免运费折扣信息 */}
      {!!totalDiscount && (
        <div className='item-block discount-block'>
          <div className='f-js-ac item-block-item'>
            <span className='main-title'>{$fm('discount_title', '折扣')}</span>

            <div className='shipping-price total-goods-price'>
              -
              {intlLiToYuan({
                currency,
                value: totalDiscount,
              })}
            </div>
          </div>
          <div className='ship-free-discount-code'>
            <svg className='ship-free-discount-code-icon' aria-hidden='true'>
              <use xlinkHref='#iconicon_discount' />
            </svg>
            <p className='ship-free-discount-text'>
              {$fm('d3fd517f819c48a8a345cb6f93e6a2de', `${discount}折`, { discount })}
            </p>
          </div>
        </div>
      )}

      {/* 总计 */}
      <div className='item-block sum-count-wrapper skiptranslate'>
        333
        <div className='font-light'>{$fm('order.address.total', '总计')}</div>
        <div className='layout-cell-right font-light skiptranslate'>
          <div className='total-price'>
            {intlLiToYuan({
              currency,
              value: totalPrice,
            })}
          </div>
        </div>
      </div>
      {/* 设置了多币种才展示这部分 */}
      {multiCurrencyInfo?.currency && (
        <div className='layout-cell-right font-light'>
          <div className='multi-currency-total-price'>
            {intlLiToYuan({
              currency,
              value: totalPrice,
              multiCurrencyInfo,
            })}
          </div>
          <div className='multi-currency-total-price-desc'>
            {$fm('c03815036df94ee686046643ff35e65c', '金额可能会因汇率而异')}
          </div>
        </div>
      )}
    </Style>
  );
};
