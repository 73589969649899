import styled from 'styled-components';

export default styled.div`
  .upload-title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    color: var(--color-text);
  }
  .upload-control {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    padding: 16px 12px;
    border: 1px dashed rgba(var(--color_body_text_rgb), 0.2);
    border-radius: 8px;
    background-color: rgba(var(--color_body_text_rgb), 0.04);
    cursor: pointer;
    svg {
      fill: rgba(var(--color_body_text_rgb), 0.6);
    }
    .upload-text {
      color: rgba(var(--color_body_text_rgb), 0.8);
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }
  }
  .input-control {
    display: none;
  }
  .file-info-box {
    display: flex;
    align-items: center;
    gap: 8px;
    .file-icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: var(--color_button);
      }
      .file-icon-image {
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }
    .file-info {
      flex: 1;
      width: 0;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .file-name {
        font-size: 12px;
        line-height: 17px;
        color: var(--color_text);
      }
      .file-upload-progress {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        .file-upload-progress-bar {
          position: relative;
          height: 2px;
          border-radius: 8px;
          background-color: rgba(var(--color_button_rgb), 0.1);
          .file-upload-progress-bar-inner {
            position: absolute;
            z-index: 2;
            height: 100%;
            top: 0;
            left: 0;
            background-color: var(--color_button);
          }
        }
        .file-upload-progress-text {
          font-size: 10px;
          line-height: 14px;
          color: rgba(var(--color_body_text_rgb), 0.6);
        }
      }
      .file-status {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 12px;
        line-height: 17px;
        .file-retry {
          display: flex;
          align-items: center;
          gap: 4px;
          color: var(--color_button);
          margin-left: 12px;
        }
      }
    }
    .file-remove {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: rgba(var(--color_button_rgb), 0.04);
      cursor: pointer;
      svg {
        fill: var(--color_button_rgb);
      }
    }
  }
`;
