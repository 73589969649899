import React, { memo } from 'react';
import copy from 'copy-to-clipboard';
import { ECARD_VERIFY_STATUS, ecardVerifyStatusText } from 'const/index';
import cn from 'classnames';
import { Icon } from 'components/base';
import { BlockLoading, Notify } from 'zent';
import Style from './style';

const OrderEcardCode = props => {
  const {
    className,
    $fm,
    qrcodeUrl = '',
    visibleQrcode,
    ecardList = [],
  } = props;

  const onCopy = (text) => {
    copy(text);
    Notify.success($fm('store.template.copy_success', '复制成功！'));
  }
  
  return (
    <Style className={cn(className)}>
      {
        qrcodeUrl ?
          <>
            {visibleQrcode && <img className='ecard-code-inner' src={qrcodeUrl} alt='' />}
            <div className="ecard-code-content">
              <div className="ecard-code-title">{$fm('order_verify_code', '券码信息')}</div>
              <ul className='ecard-code-list'>
                {ecardList?.map((item) => (
                  <li className='ecard-code-item'>
                    <div className='ecard-code-num'>
                      <span>{$fm('403739abed4b465987c3d2eb5c0670b5', '券码:{code}', { code: item.ticketCode })}</span>
                      <Icon onClick={() => onCopy(item.ticketCode)} name='iconic-fuzhi' />
                    </div>
                    <div
                      className={cn('ecard-code-status', {
                        'status-light': item.verifyStatus === ECARD_VERIFY_STATUS.notVerify,
                      })}
                    >
                      {ecardVerifyStatusText[item.verifyStatus]
                        ? $fm(ecardVerifyStatusText[item.verifyStatus].id)
                        : null}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </> :
          <BlockLoading loading={true} icon='circle' iconSize={32} />
      }
    </Style>
  )
}

export default memo(OrderEcardCode);