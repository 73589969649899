import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import HtButton from 'components/standard/button';
import { useI18n } from 'hook/intl';
import shopApi from 'api/shop';
import { qiniuImageUpload, cancelUpload } from 'utils/image';
import { get, set, isFinite, isEmpty } from 'lodash';
import Style from './style';
import { Notify } from 'zent';
import { Icon } from 'components/base';

enum FileTypeEnum {
  image = 'image',
  video = 'video',
  zip = 'zip',
  file = 'file',
}

enum FileTypeIconEnum {
  image = 'iconwenjian_tupian',
  video = 'iconwenjian_shipin',
  zip = 'iconwenjian_yasuobao',
  file = 'iconwenjian_wendang'
}

enum UploadStatusEnum {
  success = 'success',
  error = 'error',
}

interface IUploadFileProps {
  defaultValue?: {
    url: string,
    filename: string,
    fileType?: FileTypeEnum,
  },
  onChange(res: any): void,
}

const initFileTypeAndIcon = (file) => {
  let { fileType = FileTypeEnum.file, type = '' } = file;
  let fileTypeIcon = FileTypeIconEnum.file;
  if (type.startsWith('image/')) {
    fileType = FileTypeEnum.image;
    fileTypeIcon = FileTypeIconEnum.image;
  } else if (type.startsWith('video/')) {
    fileType = FileTypeEnum.video;
    fileTypeIcon = FileTypeIconEnum.video;
  } else if (['application/zip', 'application/x-rar-compressed', 'application/x-7z-compressed'].includes(type)) {
    fileType = FileTypeEnum.zip;
    fileTypeIcon = FileTypeIconEnum.zip;
  }
  file.fileType = fileType;
  file.fileTypeIcon = fileTypeIcon;
  return file;
}

const UploadFile = (props: IUploadFileProps) => {
  const intl = useIntl();
  const { $fm } = useI18n(intl);

  const { defaultValue, onChange } = props;

  const initDefaultValue = useCallback(() => {
    const { filename, fileType, url } = defaultValue || {};
    if (!filename || !url) return null;
    const file = initFileTypeAndIcon({ fileType });
    return {
      name: filename,
      url,
      fileType: file.fileType,
      fileTypeIcon: file.fileTypeIcon,
    }
  }, []);


  const [selectedFile, setSelectedFile] = useState(initDefaultValue());
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const [uploadPercent, setUploadPercent] = useState<number | null>(null);
  const [uploadStatus, setUploadStatus] = useState<UploadStatusEnum | null>(null);

  const uploadStatusText = useMemo(() => {
    return {
      [UploadStatusEnum.success]: {
        icon: 'iconic-chenggong',
        text: $fm('5983239313014f76958968b3a83026ed', '已上传'),
        style: {
          color: '#5AA84A',
          fill: '#5AA84A',
        }
      },
      [UploadStatusEnum.error]: {
        icon: 'iconic-jinggao',
        text: $fm('776c3b74c1274867a707d605b29405f1', '网络故障，请检查网络或重新上传'),
        style: {
          color: '#FF4D4F',
          fill: '#FF4D4F',
        },
      }
    }
  }, []);
  const uploadStatusInfo = uploadStatus ? uploadStatusText[uploadStatus] : null;

  const onSelectFile = async (event) => {
    let file = get(event, 'target.files[0]', null);
    if (!file) return;

    const maxSize = 50 * 1024 * 1024;
    if (file.size > maxSize) {
      Notify.warn($fm('fdb9b623d00c45289b9cbb32669c5b94', '文件大小超出限制，请重新选择文件'))
      return;
    }

    initFileTypeAndIcon(file);
    setSelectedFile(file);
    onUploadFile(file);
    if (file.fileType === FileTypeEnum.image) {
      readFile(file);
    }
  }

  const readFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setImageBase64(event.target.result);
    }
  }

  // 开始上传
  const onUploadFile = async (file) => {
    setUploadStatus(null);

    const token = await shopApi.getPrivateFileUploadToken();

    setUploadPercent(0);
    qiniuImageUpload({
      file,
      token,
      onProgress(percent) {
        setUploadPercent(percent)
      },
      onSuccess(res) {
        setUploadPercent(null);
        setUploadStatus(UploadStatusEnum.success);
        onChange && onChange({ ...res, filename: file.name, fileType: file.fileType });
      },
      onError() {
        setUploadPercent(null);
        setUploadStatus(UploadStatusEnum.error);
      }
    })
  }

  // 删除文件
  const onRemoveFile = () => {
    setSelectedFile(null);
    setUploadPercent(null);
    setUploadStatus(null);
    onChange && onChange(null);
  }

  return (
    <Style>
      {
        selectedFile ?
          <div className="file-info-box">
            <div className="file-icon">
              {
                selectedFile.fileType === FileTypeEnum.image && imageBase64 ?
                  <img className='file-icon-image' src={imageBase64} /> :
                  <Icon name={selectedFile.fileTypeIcon} size={32} />
              }
            </div>
            <div className="file-info">
              <div className="file-name">
                {selectedFile?.name}
              </div>
              {
                isFinite(uploadPercent) &&
                <div className="file-upload-progress">
                  <div className="file-upload-progress-bar">
                    <div className="file-upload-progress-bar-inner" style={{ width: `${uploadPercent}%` }}></div>
                  </div>
                  <div className="file-upload-progress-text">{`${uploadPercent}%`}</div>
                </div>
              }
              {
                uploadStatusInfo &&
                <div className="file-status">
                  <Icon name={uploadStatusInfo.icon} size={16} style={uploadStatusInfo.style} />
                  <div style={uploadStatusInfo.style}>
                    {uploadStatusInfo.text}
                  </div>
                  {
                    uploadStatus === UploadStatusEnum.error &&
                    <div className="file-retry" onClick={onUploadFile}>
                      {$fm('97582be39054462698f1340ce63ed9c8', '点击重试')}
                      <Icon name='icona-02caozuo-shuaxin' size={12} />
                    </div>
                  }
                </div>
              }
            </div>
            <div className="file-remove" onClick={onRemoveFile}>
              <Icon name='iconshanchu' size={16} />
            </div>
          </div> :
          <div className="upload-box">
            <label className='upload-control' htmlFor="uploadFile">
              <Icon name='iconshangchuanwenjian' size={24} />
              <div className="upload-text">
                {$fm('c3fa8db835974f14980c7221ead1be8a', '文件大小50M以内，支持常见图片视频、文档、压缩包等格式')}
              </div>
            </label>
            <input className='input-control' id='uploadFile' type='file' onChange={onSelectFile} />
          </div>
      }
    </Style>
  )
}


export default UploadFile;