import React, { FC, useEffect, memo } from 'react';
import ImageUpload from 'cpn/image-upload';
import { Icon } from 'components/base';
import Style from './style';
import {
  ICustomizedCheckoutProps,
  ICustomizedCheckoutItemProps,
  IOrderMessageProps,
} from './type';
import { CHECKOUT_RULE_TYPE, TEMPLATE_TYPE } from './const';
import { cloneDeep, set, debounce, isArray } from 'lodash';
import cn from 'classnames';
import { Input } from 'zent';
import { DELIVERY_METHOD } from '../../const';
import UploadFile from 'components/page/upload-file';

const CustomizedCheckout = (props: ICustomizedCheckoutProps) => {
  const {
    intl,
    $fm,
    checkoutSetting = {},
    customizedCheckoutData = [],
    changeCustomizedCheckoutData,
    orderMessage,
    setOrderMessage,
    orderMessageError,
    setOrderMessageError,
  } = props;

  const handleChangeCheckoutData = (key, value) => {
    const newData = cloneDeep(customizedCheckoutData);
    set(newData, key, value);
    changeCustomizedCheckoutData(newData);
  };

  return (
    <Style>
      <div className='customized-checkout-title'>{$fm('d258a792ca3b439c9a4247a1f8aa6214', '其他信息')}</div>
      <div className='customized-checkout-list'>
        {customizedCheckoutData.map((item, index) => (
          <CustomizedCheckoutItem
            intl={intl}
            $fm={$fm}
            item={item}
            index={index}
            key={`customized-checkout-item-${item.moduleId}`}
            handleChange={(key, value) => handleChangeCheckoutData(key, value)}
          />
        ))}
        <OrderMessageItem
          $fm={$fm}
          checkoutSetting={checkoutSetting}
          orderMessage={orderMessage}
          setOrderMessage={setOrderMessage}
          orderMessageError={orderMessageError}
          setOrderMessageError={setOrderMessageError}
        />
      </div>
    </Style>
  );
};

const CustomizedCheckoutItem: FC<ICustomizedCheckoutItemProps> = (props) => {
  const { intl, $fm, item, index, handleChange } = props;
  const { type, items, extra, visible, moduleId } = item;

  // 商品, 地区不匹配则隐藏
  if (type === CHECKOUT_RULE_TYPE.idcard && !visible) return null;

  const handleChangeImage = (ci, key, data) => {
    const url = data?.[0] || '';
    const newCItem = cloneDeep(items[ci]);
    if (url) {
      newCItem[key] = url;
      newCItem.errorMsg = '';
    } else {
      newCItem[key] = '';
    }
    handleChange(`[${index}].items.[${ci}]`, newCItem);
  };

  const handleChangeImageCustom = (ci, key, data) => {
    data = isArray(data) ? data : [];
    const newCItem = cloneDeep(items[ci]);
    if (data) {
      newCItem[key] = data;
      newCItem.errorMsg = '';
    } else {
      newCItem[key] = '';
    }
    if (newCItem[key]) {
      newCItem.errorMsg = '';
    }
    handleChange(`[${index}].items.[${ci}]`, newCItem);
  };

  const handleChangeFile = (ci, data) => {
    const { filename, url, fileType } = data || {};
    const newCItem = cloneDeep(items[ci]);
    if (filename && url) {
      newCItem.FILE_NAME = filename;
      newCItem.FILE_TYPE = fileType;
      newCItem.value = url;
      newCItem.errorMsg = '';
    } else {
      newCItem.value = '';
      newCItem.FILE_NAME = '';
      newCItem.FILE_TYPE = '';
    }
    if (newCItem.value) {
      newCItem.errorMsg = '';
    }
    handleChange(`[${index}].items.[${ci}]`, newCItem);
  };

  const { NUMBER_OF_IMAGES: imageMaxLength = 1 } = item?.extra || {};

  return (
    items.map((cItem, ci) => {
      return (
        <div key={`${moduleId}-${cItem.id}`} className='customized-checkout-list-item'>
          <div className='list-item-box'>
            <div className={cn('list-item__left', { 'label-required': Boolean(item.required) })}>
              <div>
                {cItem.label}
                {imageMaxLength > 1 ? `（${(cItem?.NUMBER_OF_IMAGES || []).length}/${imageMaxLength}）` : ''}
              </div>
            </div>
            <div className='list-item__right'>
              {cItem.type === TEMPLATE_TYPE.text && (
                <Input
                  key={`input-${cItem.id}`}
                  type={extra?.TEXT_IS_MULTI_TEXT === 1 ? 'textarea' : 'text'}
                  placeholder={$fm('common_please_input', '请输入')}
                  value={cItem.value || ''}
                  onChange={(e) =>
                    handleChange(
                      `[${index}].items.[${ci}].value`,
                      e.target.value?.substr(0, extra?.TEXT_IS_MULTI_TEXT === 1 ? 300 : 50),
                    )
                  }
                  onBlur={() => {
                    if (cItem.value && item.required) {
                      handleChange(`[${index}].items.[${ci}].errorMsg`, '');
                    }
                  }}
                  maxCharacterCount={extra?.TEXT_IS_MULTI_TEXT === 1 ? 300 : 50}
                  showCount={extra?.TEXT_IS_MULTI_TEXT === 1}
                />
              )}
              {cItem.type === TEMPLATE_TYPE.image ? (
                type === CHECKOUT_RULE_TYPE.idcard ? (
                  <>
                    {item?.extra?.ID_CARD_IS_FRONT === 1 && (
                      <div className='upload-block'>
                        <ImageUpload
                          key={`upload-image-card-front-${cItem.id}`}
                          multiple={false}
                          intl={intl}
                          maxAmount={1}
                          fileSize={10}
                          accept='.jpg,.png,.bmp,.jpeg'
                          placeholderSlot={
                            <div className='upload-placeholder'>
                              <Icon name='iconshangchuantupian' />
                              <span>{$fm('b1585145b9c744b399256cbefe8aa8fe', '正面')}</span>
                            </div>
                          }
                          defaultValue={[cItem?.ID_CARD_IS_FRONT]}
                          onChange={(data) => handleChangeImage(ci, 'ID_CARD_IS_FRONT', data)}
                        />
                      </div>
                    )}
                    {item?.extra?.ID_CARD_IS_REVERSE === 1 && (
                      <div className='upload-block'>
                        <ImageUpload
                          key={`upload-image-card-reverse-${cItem.id}`}
                          multiple={false}
                          intl={intl}
                          maxAmount={1}
                          fileSize={10}
                          accept='.jpg,.png,.bmp,.jpeg'
                          placeholderSlot={
                            <div className='upload-placeholder'>
                              <Icon name='iconshangchuantupian' size='xSmall' />
                              <span>{$fm('407adc54839f4a72a554e7479cfc1491', '反面')}</span>
                            </div>
                          }
                          defaultValue={[cItem?.ID_CARD_IS_REVERSE]}
                          onChange={(data) => handleChangeImage(ci, 'ID_CARD_IS_REVERSE', data)}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className='upload-block image'>
                    <ImageUpload
                      key={`image-${cItem.id}`}
                      multiple={imageMaxLength !== 1}
                      intl={intl}
                      maxAmount={item?.extra?.NUMBER_OF_IMAGES || 1}
                      fileSize={10}
                      accept='.jpg,.png,.bmp,.jpeg'
                      placeholderSlot={
                        <div className='upload-placeholder'>
                          <Icon name='iconshangchuantupian' size='xSmall' />
                        </div>
                      }
                      defaultValue={cItem?.NUMBER_OF_IMAGES || []}
                      // initialValue={cItem?.NUMBER_OF_IMAGES || []}
                      onChange={(data) => handleChangeImageCustom(ci, 'NUMBER_OF_IMAGES', data)}
                    />
                  </div>
                )
              ) : null}
              {cItem.type === TEMPLATE_TYPE.file ? (
                <div className='input-block'>
                  <UploadFile
                    defaultValue={{ url: cItem.value, filename: cItem.FILE_NAME, fileType: cItem.FILE_TYPE }}
                    onChange={(data) => handleChangeFile(ci, data)}
                  />
                </div>
              ) : null}
            </div>
            {cItem.errorMsg && <div className='item-error-msg'>{cItem.errorMsg}</div>}
          </div>
        </div>
      );
    })
  );
};

const OrderMessageItem: FC<IOrderMessageProps> = (props) => {
  const { $fm, checkoutSetting, orderMessage, setOrderMessage, orderMessageError, setOrderMessageError } = props;

  return (
    <div className='customized-checkout-list-item checkout-order-message'>
      <div className='list-item-box'>
        <div className={cn('list-item__left', { 'label-required': Boolean(checkoutSetting.requiresMessage) })}>
          <div>{$fm('971ce28231234ccd8f24aca85a29e41e', '订单留言')}</div>
          {checkoutSetting.messageDesc && <div className='message-desc'>{checkoutSetting.messageDesc}</div>}
        </div>
        <div className='list-item__right'>
          <Input
            type='textarea'
            placeholder={$fm('common_please_input', '请输入')}
            value={orderMessage}
            onChange={(e) => setOrderMessage(e.target.value?.substr(0, 300))}
            onBlur={() => {
              if (orderMessage) {
                setOrderMessageError('');
              }
            }}
            maxCharacterCount={300}
            showCount
          />
        </div>
      </div>
      {orderMessageError && <div className='item-error-msg'>{orderMessageError}</div>}
    </div>
  );
};

export default memo(CustomizedCheckout);
