import React from 'react';
import HtDialog from 'components/standard/dialog';
import { Button } from 'zent';
import { intlLiToYuan } from 'utils/money';
import { useI18n } from 'hook/intl';
import Style from './style';

const { openHtDialog, closeHtDialog } = HtDialog;

const dialogId = 'openTaxDetailDialog';

const TaxDetailModalContent = ({ intl, taxInfo, currency, isIncludeTaxAndFreeTaxPrice }) => {
  const { $fm } = useI18n(intl);
  const { freeTax = 0, freeTaxPrice, salesTaxDetails, shippingTaxDetail, shippingTaxDetails, totalTaxPrice } = taxInfo;

  return (
    <Style>
      <div className='tax-detail-content'>
        {salesTaxDetails?.map((item) => (
          <div className='tax-item' key={item.uniqueName}>
            <div className='tax-item__label'>{`${$fm('1b386589a9cd4c34be56e20a0717c4ee', '商品税')}·${
              item.taxName || 'Tax'
            }（${item.taxRate / 1000}%）`}</div>
            <div className='tax-item__value'>
              {isIncludeTaxAndFreeTaxPrice && '-'}
              {intlLiToYuan({
                currency,
                value: item.taxPrice,
              })}
            </div>
          </div>
        ))}
        {shippingTaxDetails?.map((item) => (
          <div className='tax-item' key={item.uniqueName}>
            <div className='tax-item__label'>{`${$fm('2e5f153d67f948a79c4439124435d41c', '运费税')}·${
              item.taxName || 'Tax'
            }（${item.taxRate / 1000}%）`}</div>
            <div className='tax-item__value'>
              {isIncludeTaxAndFreeTaxPrice && '-'}
              {intlLiToYuan({
                currency,
                value: item.taxPrice,
              })}
            </div>
          </div>
        ))}
        {!!freeTaxPrice && !isIncludeTaxAndFreeTaxPrice && (
          <div className='tax-item'>
            <div className='tax-item__label'>{$fm('96d37d15c9114e91848e9ea46da32b9c', '免税客户')}</div>
            <div className='tax-item__value'>
              -
              {intlLiToYuan({
                currency,
                value: freeTaxPrice,
              })}
            </div>
          </div>
        )}
      </div>
      <div className='tax-item'>
        <div className='tax-item__label'>{$fm('order.address.total', '总计')}</div>
        <div className='tax-item__value'>
          {isIncludeTaxAndFreeTaxPrice && '-'}
          {intlLiToYuan({
            currency,
            value: isIncludeTaxAndFreeTaxPrice ? freeTaxPrice : totalTaxPrice,
          })}
        </div>
      </div>
      {isIncludeTaxAndFreeTaxPrice && (
        <div className='tax-free-customer'>{$fm('96d37d15c9114e91848e9ea46da32b9c', '免税客户')}</div>
      )}
    </Style>
  );
};

const openTaxDetailModal = ({ theme, intl, taxInfo, currency, isIncludeTaxAndFreeTaxPrice }) => {
  openHtDialog({
    className: 'tax-detail-dialog',
    theme,
    dialogId,
    title: intl.formatMessage({ id: 'order.tax.discount', defaultMessage: '税费' }),
    children: (
      <TaxDetailModalContent
        intl={intl}
        taxInfo={taxInfo}
        currency={currency}
        isIncludeTaxAndFreeTaxPrice={isIncludeTaxAndFreeTaxPrice}
      />
    ),
    footer: (
      <Button type='primary' onClick={() => closeHtDialog(dialogId)}>
        {intl.formatMessage({ id: 'common_confirm', defaultMessage: '确定' })}
      </Button>
    ),
  });
};

export default openTaxDetailModal;
