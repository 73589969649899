import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Button, Form, Checkbox } from 'zent';
import { buriedPoint } from 'utils/log-buried-point';
import { StandardGoodsItem } from 'cpn/goods-item';
import { intlLiToYuan } from 'utils/money';
import { GROUP_BUYING } from 'utils/local-storage/config';
import { PROMOTION_DESCRIPTIONS_SUBTYPE, PROMOTION_DESCRIPTIONS_TYPE, STEP_MAP } from '../../const';
import Style from './style';
import RootContext from '../../../context/root';
import { ContextOrder } from '../../../context/context-order';
import { AFFILIATE_DISCOUNT_COPY } from 'const/distribution';
import PromotionDescriptions from './promotion-descriptions';
import jsCookie from 'js-cookie';
import { DISCOUNT_CODE } from '../../../../const/index';
import { isEmpty } from 'lodash';
import cn from 'classnames';
import PointsSelector from './components/points-selector';
import { useI18n } from 'hook/intl';
import { AdditionalFeeTotal } from 'cpn/additional-fee-total';
import { Icon } from 'components/base';
import openTaxDetailModal from '../../../components/open-tax-detail-dialog';
import OrderTotalDetails from './components/order-total-details';

enum INVALID_REASON_ENUM {
  notSupportPickup = 'not_support_pickup',
  soldOut = 'sold_out',
  sellOut = 'sell_out',
  deleted = 'deleted',
  unSold = 'un_sold',
  limited = 'limited',
  lack = 'lack',
}

// 失效原因
const getInvalidReasonsIntl = ($fm, reason: INVALID_REASON_ENUM) => {
  const maps = {
    [INVALID_REASON_ENUM.limited]: {
      text: $fm('3384f7a9e322499996fee848dee02b43', '商品超出可购数量'),
    },
    [INVALID_REASON_ENUM.notSupportPickup]: {
      text: $fm('cc63034757974c01ab1881875856a7cb', '商品不支持该配送方式'),
    },
    [INVALID_REASON_ENUM.lack]: {
      text: $fm('1f846f65b98241b693dddfbd9751ce59', '商品不满足起购数量'),
    },
  };
  const defaultText = $fm('order.item.all.invalid', '商品已失效');
  return <div className='invalid-reason'>{maps?.[reason]?.text || defaultText}</div>;
};

const GoodsTotal = ({
  step,
  completeForm,
  orderDetail = {},
  isAppleGooglePay,
  setAppleGoogleBtnRef,
  needShip,
  isPickup,
  isPointsExchange = false,
}) => {
  const { initialData = {}, member, theme } = useContext(RootContext);
  const { taxInfo } = orderDetail;
  const { freeTaxPrice = 0, totalTaxPrice = 0 } = taxInfo || {};

  const [isSpread, setIsSpread] = useState(false); // 展开按钮

  // Apple Google Pay DOM
  const appleGoogleBtnRef = useRef(null);
  useEffect(() => {
    if (appleGoogleBtnRef.current) {
      setAppleGoogleBtnRef(appleGoogleBtnRef.current);
    }
  }, [appleGoogleBtnRef.current]);

  const language = initialData?.shopSetting?.language;

  const {
    refer,
    priceInfo = {},
    discountInfo = {},
    checkoutPreview,
    orderInfo = {},
    lineItems = [],
    isSinglePageOrder,
    emailReg,
    currency,
    deliveryInfo = {},
    orderLoading,
    pointsInfo = {},
    needAgreement,
    agreementEle,
    agreementText,
    isAgree,
    setIsAgree,
    additionalFees,
  }: any = useContext(ContextOrder);
  const { multiCurrencyInfo, userInfo } = initialData;
  const isGroupBuying = refer === GROUP_BUYING; // 是否是拼团单
  const isLogin = userInfo && !isEmpty(userInfo);
  // const needShip = !!orderInfo?.requiresShipping; // 是否需要物流
  const { shippingInfo = {} } = deliveryInfo;
  const { discountCode = '', discountMode, resultMessage, success } = discountInfo;

  const isAutoDiscount = discountMode === 'automaticDiscount'; // 是否自动折扣
  const [discountCodeByInput, setDiscountCodeByInput] = useState(isAutoDiscount ? '' : discountCode);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const { $fm } = useI18n(intl);
  const memberTitle = member?.memberStatus?.memberPlanInfo?.title;
  // 提示语存在，且不是成功且是折扣码折扣【非自动折扣】 才显示
  const isMatchAutoDiscount = Number(success) === 1;

  const promotionDescriptions = orderDetail?.promotionDescriptions || [];

  const showErrorMessage = resultMessage && resultMessage !== 'success' && success === 0;
  const silentDiscountCode = jsCookie.get(DISCOUNT_CODE); // 通过分享链接进入的折扣码;
  const hasShipping = !!shippingInfo?.shippingRateIds?.[0]; // 是否有可选物流
  const hasAddress = !!orderDetail?.shippingAddress?.phone; // 是否有地址

  const hasCustomerInfo = useMemo(() => {
    // 可能存在手机号登录的情况，就没有邮箱
    return (
      (orderDetail?.buyerInfo?.customerNo && emailReg.test(orderDetail?.buyerInfo?.customerNo)) ||
      (!!orderDetail?.buyerInfo?.customerId && isLogin)
    );
  }, [orderDetail?.buyerInfo]);

  const submitBtnDisabled = (needShip && !hasAddress) || (needShip && !hasShipping) || !hasCustomerInfo;

  const { totalDiscount = 0, totalLineItemsPrice = 0, totalPrice = 0, totalShippingPrice = 0 } = priceInfo;

  const onDiscountChange = (event) => {
    const value = event?.target?.value;
    setDiscountCodeByInput(value);
  };

  const logToTracker = () => {
    buriedPoint('checkout_inputcode');
  };

  // 分享带入的折扣码，在第一次使用完后就需要调用这个函数，删除
  const removeSilentDiscountCode = () => {
    jsCookie.remove(DISCOUNT_CODE);
  };

  const applyDiscountCode = (code) => {
    checkoutPreview({ discountInfo: { discountCode: code } });
  };

  useEffect(() => {
    // 初始化进入信息页面，若cookie中存在折扣码，则需要静默使用折扣码
    const code = silentDiscountCode || sessionStorage.getItem(AFFILIATE_DISCOUNT_COPY);
    if (orderInfo?.token && code && !discountCode) {
      applyDiscountCode(code);
    }
  }, [orderInfo?.token]);

  useEffect(() => {
    if (discountCode && discountCode === silentDiscountCode) {
      removeSilentDiscountCode();
    }
  }, [discountCode]);

  // 有效商品
  const validGoods = useMemo(() => {
    return lineItems?.filter((item) => item.isInvalid === 0);
  }, [lineItems]);
  // 无效商品
  const inValidGoods = useMemo(() => {
    return lineItems?.filter((item) => item.isInvalid === 1);
  }, [lineItems]);


  const btnText = useMemo(() => {
    if (isPointsExchange && totalPrice === 0) {
      return $fm('fe3190ea29ae42f1b55f6089b82d4b97', '立即兑换');
    }
    return $fm('82255fdf2a344a43b51150dff6b6593a', '安全支付');
  }, [orderInfo, isPointsExchange, totalPrice]);

  const toPolicy = (e) => {
    e.preventDefault();
    window.open('/policy/Policy-Data');
  };

  // 是否展示折扣
  // 0 元单和再次支付不展示, 折后 0 元照常展示
  const isVisibeDiscount = discountCode || (priceInfo.totalPrice !== 0 && !orderInfo.isTriggeredPay);

  return (
    <Style>
      <div className='item-block item-goods-block'>
        <div className='item-goods-block-content'>
          {validGoods?.map((item, index) => (
            <StandardGoodsItem
              discountInfo={discountInfo}
              item={item}
              currency={currency}
              key={`${item?.variantId}-${index}`}
              isOrderGoods
              isGroupBuying={isGroupBuying}
              priceAlignType='row-reverse'
              isSetBgColor
            />
          ))}
          {inValidGoods && inValidGoods?.length === 1 && (
            <>
              <div className='invalid-title'>{getInvalidReasonsIntl($fm, inValidGoods[0].invalidReason)}</div>
              <StandardGoodsItem
                discountInfo={discountInfo}
                item={inValidGoods[0]}
                currency={currency}
                key={`${inValidGoods[0]?.variantId}-0`}
                isOrderGoods
                isGroupBuying={isGroupBuying}
                priceAlignType='row-reverse'
                isSetBgColor
              />
            </>
          )}
          {inValidGoods && inValidGoods?.length > 1 && (
            <>
              <div className='invalid-title'>
                {$fm('278380c64f134950b43b4ad8e7b4ff4c', '失效商品')}
                {` (${inValidGoods.length}) `}
              </div>
              {inValidGoods?.slice(0, isSpread ? inValidGoods?.length : 1)?.map((item, index) => (
                <StandardGoodsItem
                  discountInfo={discountInfo}
                  item={item}
                  currency={currency}
                  key={`${item?.variantId}-${index}`}
                  isOrderGoods
                  isGroupBuying={isGroupBuying}
                  priceAlignType='row-reverse'
                  isSetBgColor
                  invalidReason={item.invalidReason ? getInvalidReasonsIntl($fm, item.invalidReason) : null}
                  includeTaxPrice={orderInfo?.includeTaxPrice}
                />
              ))}
              <div className='list-edit'>
                <span onClick={() => setIsSpread(!isSpread)}>
                  {isSpread ? $fm('put_away', '收起') : $fm('design.preview_all', '查看全部')}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      {/* 支付完成不显示输入折扣码 拼团单不显示折扣 */}
      {!isGroupBuying && isVisibeDiscount && (
        <div className='item-block'>
          {/* 展示折扣码错误信息 */}
          {showErrorMessage && (step !== STEP_MAP.INFO || isSinglePageOrder) && (
            <div className='discount-code-invalid-text'>
              <p>{resultMessage}</p>
              <p className='discount-code-invalid-text-del-icon'>
                <svg
                  onClick={() => {
                    applyDiscountCode('');
                  }}
                  className='discount-code-invalid-del-icon'
                  aria-hidden='true'
                >
                  <use xlinkHref='#iconic-xiala1' />
                </svg>
              </p>
            </div>
          )}
          {(step !== STEP_MAP.INFO || isSinglePageOrder) && (
            <div className='discount-item'>
              <Input
                value={discountCodeByInput}
                onChange={onDiscountChange}
                onBlur={logToTracker}
                className='discount-code-input'
                placeholder={intl.formatMessage({
                  id: 'please.use.discount.code',
                })}
                disabled={orderInfo?.isTriggeredPay}
                showClear
                inline
              />
              <Button
                disabled={loading || !discountCodeByInput || orderInfo?.isTriggeredPay}
                onClick={() => applyDiscountCode(discountCodeByInput)}
                loading={loading}
                type='primary'
              >
                {/* 使用 */}
                <FormattedMessage id='common.use' />
              </Button>
            </div>
          )}

          {/* 折扣码展示 */}
          {discountInfo?.discountCode && !isAutoDiscount && isMatchAutoDiscount && (
            <div className='discount-input-code-detail'>
              <svg className='discount-code-icon' aria-hidden='true'>
                <use xlinkHref='#iconicon_discount' />
              </svg>
              <span className='discount-code'>{discountInfo?.discountCode}</span>
              {!orderInfo?.isTriggeredPay && (
                <svg onClick={() => applyDiscountCode('')} className='discount-del-code-icon' aria-hidden='true'>
                  <use xlinkHref='#iconic-shanchu' />
                </svg>
              )}
            </div>
          )}
        </div>
      )}

      <OrderTotalDetails
        orderDetail={orderDetail}
        isPointsExchange={isPointsExchange}
        needShip={needShip}
        isPickup={isPickup}
      />

      {isSinglePageOrder && completeForm && (
        <div className='sing-page-pay-wrapper'>
          <div className='safe-pay-form-wrapper'>
            {needAgreement && (
              <Checkbox className='agreement-checkbox-wrap' checked={isAgree} onChange={() => setIsAgree(!isAgree)}>
                <div className='agreement-content'>
                  <span>{$fm('c6d82602e3c547a5a048230c3cb61d02', '已经阅读并同意')}</span>&nbsp;
                  <span className='agreement-text'>{agreementEle}</span>
                </div>
              </Checkbox>
            )}
            <Form form={completeForm}>
              <div className='pay-action-wrapper'>
                {!isAppleGooglePay && (
                  <Button type='primary' htmlType='submit' disabled={submitBtnDisabled} loading={orderLoading}>
                    {btnText}
                  </Button>
                )}
                {/* 多页支付 Apple Google Pay 支付按钮 */}
                <div
                  ref={appleGoogleBtnRef}
                  className={cn('payment-request-button-wrap', submitBtnDisabled && 'payment-request-button-disabled')}
                  style={{ display: isAppleGooglePay ? 'block' : 'none' }}
                >
                  <div id='payment-request-button' />
                </div>
              </div>
              <div className='safe-pay-wrapper'>
                <svg width={20} height={20} fill='#48AB7C'>
                  <use xlinkHref='#iconanquanbaozhang' />
                </svg>
                <div className='description'>
                  {$fm(
                    '7e229c5e1fdf4487b103dfee03a4ae4f',
                    '使用 SSL 协议保证支付安全，在线支付时，您的银行卡详细信息受到保护',
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Style>
  );
};

export default GoodsTotal;
