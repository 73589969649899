import { useState, useEffect, useCallback, useMemo } from 'react';
import { ITemplateModuleVO } from '../component/customized-checkout/type';
import { CHECKOUT_RULE_TYPE, TEMPLATE_TYPE, CustomizedCheckoutIDCardKeyEnum, GoodsRangeEnum, CountryRangeEnum } from '../component/customized-checkout/const';
import LocalStorage from 'utils/local-storage';
import { set, debounce, cloneDeep, get, isEmpty } from 'lodash';
import { DELIVERY_METHOD } from '../const';
import { Notify } from 'zent';

const CUSTOMIZED_CHECKOUT_STORAGE = 'customizedCheckoutInfo';

export const useCustomizedCheckout = (props) => {
  const {
    $fm,
    orderKey = '',
    checkoutSetting = {},
    customizedFields = {},
    lineItems = [],
    shippingAddress,
    requiresShipping,
    deliveryInfo,
  } = props;

  // 自定义结账数据
  const [customizedCheckoutData, setCustomizedCheckoutData] = useState<ITemplateModuleVO[]>([]);
  // 订单备注
  const [orderMessage, setOrderMessage] = useState<string>('');
  const [orderMessageError, setOrderMessageError] = useState<string>('');
  // 同意协议
  const [isAgree, setIsAgree] = useState(false);

  useEffect(() => {
    if (!orderKey || isEmpty(checkoutSetting) || isEmpty(customizedFields)) return;
    // 前端初始化显隐状态
    const modules = checkoutSetting?.customizedCheckoutFields?.modules?.map((item) => Object.assign(item, { visible: true })) || [];
    const fields = customizedFields?.fields || [];
    const storageCheckoutInfo = LocalStorage.get(CUSTOMIZED_CHECKOUT_STORAGE) || {};
    const storageMessage = storageCheckoutInfo?.[orderKey]?.message || '';
    const message = customizedFields?.message || storageMessage || '';
    if (Array.isArray(modules) && modules.length > 0) {
      // 有缓存拿缓存
      const currentCheckoutInfo = storageCheckoutInfo?.[orderKey];
      if (currentCheckoutInfo) {
        const storageFields = currentCheckoutInfo?.fields || [];
        modules.forEach((module, i) => {
          module.items.forEach((componentItem, ci) => {
            const findItem = storageFields
              ?.find((item) => item.moduleId === module.moduleId)
              ?.items?.find((cItem) => cItem.id === componentItem.id);
            if (findItem) {
              findItem.errorMsg = '';
              set(modules, `[${i}].items.[${ci}]`, Object.assign(findItem, componentItem));
            }
          });
        });
      } else if (fields.length > 0) {
        // 无缓存获取 checkoutPreviewDetail/getConfirmDetail 返回的自定义结算数据
        modules.forEach((module, i) => {
          module.items.forEach((componentItem, ci) => {
            const findItem = fields?.find((item) => item.id === componentItem.id);
            if (findItem) {
              const { moduleType, templateItemType, values = [] } = findItem;
              switch (moduleType) {
                case CHECKOUT_RULE_TYPE.idcard: {
                  if (templateItemType === TEMPLATE_TYPE.text) {
                    values?.forEach(({ k, v }) => {
                      set(modules, `[${i}].items.[${ci}].value`, v || '');
                    });
                  }
                  if (templateItemType === TEMPLATE_TYPE.image) {
                    values?.forEach(({ k, v }) => {
                      set(modules, `[${i}].items.[${ci}].${k}`, v || '');
                    });
                  }
                  break;
                }
                case CHECKOUT_RULE_TYPE.image: {
                  const images: any[] = [];
                  values?.forEach(({ fid, v }) => {
                    images.push({
                      imgId: fid,
                      url: v,
                    });
                    set(modules, `[${i}].items.[${ci}].${CustomizedCheckoutIDCardKeyEnum.NUMBER_OF_IMAGES}`, images);
                  });
                  break;
                }
                case CHECKOUT_RULE_TYPE.file: {
                  values?.forEach(({ k, v }) => {
                    if (k === CustomizedCheckoutIDCardKeyEnum.FILE_NAME) {
                      set(modules, `[${i}].items.[${ci}].${k}`, v || '');
                    } else {
                      set(modules, `[${i}].items.[${ci}].value`, v || '');
                    }
                  });
                  break;
                }
                default: {
                  values?.forEach(({ v }) => {
                    set(modules, `[${i}].items.[${ci}].value`, v || '');
                  });
                  break;
                }
              }
            }
          });
        });
      }
      // 初始化完成, 再判断身份证信息是否需要展示
      const findIndex = modules.findIndex((item) => item.type === CHECKOUT_RULE_TYPE.idcard);
      if (findIndex >= 0) {
        const findItem = modules[findIndex];
        let isExistGoods = true;
        let isExistCountry = true;
        if (findItem?.effectiveRange?.goodsRange === GoodsRangeEnum.products) {
          // 判断是否在商品中
          const ids = findItem?.effectiveRange?.goodsRangeData?.map((item) => item.goodsId) || [];
          const isExist = lineItems.some((item) => ids?.includes(item.goodsId));
          isExistGoods = isExist;
        } else if (findItem?.effectiveRange?.goodsRange === GoodsRangeEnum.collections) {
          // 判断是否在商品分组中
          const ids = findItem?.effectiveRange?.goodsCollectionRangeData?.map((item) => item.collectionId) || [];
          const isExist = lineItems.some((item) => {
            if (!item.collectionIds) return false;
            const collectionIds = JSON.parse(item.collectionIds);
            try {
              return collectionIds.some((collectionId) => ids?.includes(collectionId));
            } catch (err) {
              return false;
            }
          });
          isExistGoods = isExist;
        }
        if (requiresShipping === 1) {
          let countryCode = '';
          if (findItem?.effectiveRange?.countryRange === CountryRangeEnum.countries) {
            if (deliveryInfo?.selectedDeliveryMethod === DELIVERY_METHOD.pickup) {
              const selectedLocation = deliveryInfo?.locationList?.find((item) => item.selected === 1);
              if (selectedLocation) {
                countryCode = selectedLocation.countryCode;
              }
            } else {
              countryCode = shippingAddress?.countryCode;
            }
            if (countryCode) {
              const countryCodeList = findItem?.effectiveRange?.countryRangeData?.map((item) => item.countryCode) || [];
              const isExist = countryCodeList.includes(countryCode?.toUpperCase());
              isExistCountry = isExist;
            } else {
              isExistCountry = false;
            }
          } else {
            isExistCountry = true;
          }
        } else {
          const countryRangeData = findItem?.effectiveRange?.countryRangeData || [];
          // 虚拟商品 指定地区也会展示
          isExistCountry = countryRangeData.length === 0;
        }
        // 同时命中限制规则
        const visible = isExistGoods && isExistCountry;
        set(modules[findIndex], 'visible', visible);
      }
    }
    setCustomizedCheckoutData(modules);
    setOrderMessage(message);
    changeOrderStoreCheckoutInfo(orderKey, { message, fields: modules });
  }, [checkoutSetting, customizedFields, lineItems, shippingAddress, requiresShipping, deliveryInfo]);

  // 是否需要同意勾选协议
  const needAgreement = useMemo(() => {
    if (isEmpty(customizedCheckoutData)) return false;
    return customizedCheckoutData.some((item) => item.type === CHECKOUT_RULE_TYPE.idcard && item.visible);
  }, [customizedCheckoutData]);

  const changeCustomizedCheckoutData = (data) => {
    setCustomizedCheckoutData(data);
    changeOrderStoreCheckoutInfo(orderKey, { fields: data });
  };

  const handleChangeOrderMessage = (value) => {
    setOrderMessage(value);
    changeOrderStoreCheckoutInfo(orderKey, {
      message: value,
    });
  };

  // 更新缓存
  const changeOrderStoreCheckoutInfo = useCallback(
    debounce((orderKey, data) => {
      if (!orderKey) return;
      const customizedCheckoutInfo = LocalStorage.get(CUSTOMIZED_CHECKOUT_STORAGE) || {};
      customizedCheckoutInfo[orderKey] = {
        ...(customizedCheckoutInfo[orderKey] || {}),
        ...data,
      };
      LocalStorage.set(CUSTOMIZED_CHECKOUT_STORAGE, customizedCheckoutInfo, 1);
    }, 200),
    [],
  );

  // 数据校验
  const customizedCheckoutValidator = useCallback(() => {
    if (checkoutSetting.requiresMessage === 1 && !orderMessage?.trim()) {
      scrollIntoOrderMessage();
      setOrderMessageError($fm('bd09404f67144690a0b38fc79f111d83', '请补充买家留言'));
      return false;
    }
    if (isEmpty(customizedCheckoutData)) return true;
    const newCustomizedCheckoutData = cloneDeep(customizedCheckoutData);
    for (const item of newCustomizedCheckoutData) {
      const { required, extra, items, type, visible } = item;
      if (!required || !visible) continue;
      for (const cItem of items) {
        const { type: cType, value: cValue, label } = cItem;
        // 文本类
        if (cType === TEMPLATE_TYPE.text) {
          if (!cValue) {
            const errMsg = $fm('common_please_input', '请输入');
            set(cItem, 'errorMsg', errMsg + label);
            changeCustomizedCheckoutData(newCustomizedCheckoutData);
            scrollIntoForm();
            return false;
          } else {
            set(cItem, 'errorMsg', '');
          }
        }
        // 图片文件类
        const selectErrMsg = $fm('common_please_select', '请选择');
        if (cType === TEMPLATE_TYPE.image) {
          // 身份证需要判断 ID_CARD_IS_FRONT, ID_CARD_IS_REVERSE
          if (type === CHECKOUT_RULE_TYPE.idcard) {
            if (
              (extra?.ID_CARD_IS_FRONT === 1 && !cItem.ID_CARD_IS_FRONT) ||
              (extra?.ID_CARD_IS_REVERSE === 1 && !cItem.ID_CARD_IS_REVERSE)
            ) {
              set(cItem, 'errorMsg', selectErrMsg + label);
              changeCustomizedCheckoutData(newCustomizedCheckoutData);
              scrollIntoForm();
              return false;
            } else {
              set(cItem, 'errorMsg', '');
            }
          } else {
            // 图片的校验规则变更
            if (!cItem?.NUMBER_OF_IMAGES?.length) {
              set(cItem, 'errorMsg', selectErrMsg + label);
              changeCustomizedCheckoutData(newCustomizedCheckoutData);
              scrollIntoForm();
              return false;
            } else {
              set(cItem, 'errorMsg', '');
            }
          }
        }
        if (cType === TEMPLATE_TYPE.file) {
          if (!cValue) {
            set(cItem, 'errorMsg', selectErrMsg + label);
            changeCustomizedCheckoutData(newCustomizedCheckoutData);
            scrollIntoForm();
            return false;
          } else {
            set(cItem, 'errorMsg', '');
          }
        }
      }
    }
    changeCustomizedCheckoutData(newCustomizedCheckoutData);
    if (needAgreement && !isAgree) {
      Notify.warn($fm('56e2e91feff649b4958e0c882729639a', '请同意《数据授权确认书》'));
      return false;
    }
    return true;
  }, [checkoutSetting, customizedCheckoutData, orderMessage, needAgreement, isAgree]);

  const scrollIntoForm = () => {
    const ele = document.querySelector('.customized-checkout-list');
    ele?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const scrollIntoOrderMessage = () => {
    const ele = document.querySelector('.checkout-order-message');
    ele?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return {
    customizedCheckoutData,
    changeCustomizedCheckoutData,
    orderMessage,
    orderMessageError,
    setOrderMessage: handleChangeOrderMessage,
    setOrderMessageError,
    customizedCheckoutValidator,
    needAgreement,
    isAgree,
    setIsAgree,
  };
};
