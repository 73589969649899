import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { get, isEmpty, omit } from "lodash";
import orderApi from 'api/order';
import { ORDER_STATUS, DELIVERY_METHOD, ECARD_VERIFY_STATUS } from 'const/index';
import { OrderType } from 'const/order';
import { PackageCode } from 'const/shop';
import QRCode from 'qrcode';

export const useOrderCode = (props) => {
  const { orderInfo, refresh } = props;

  const [nativeOrderInfo, setNativeOrderInfo] = useState(orderInfo);
  const [qrcodeUrl, setQrcodeUrl] = useState('');
  const pollingFlag = useRef(false);

  const isPickupOrder = get(orderInfo, 'deliveryMethod') === DELIVERY_METHOD.pickup; // 自提单
  const isAppointmentOrder = get(orderInfo, 'orderType') === OrderType.AppointmentService; // 预定订单
  const isECardOrder = get(orderInfo, 'isEcard') === 1; // 卡券订单
  const isPandaVersion = get(__isServer ? global : window, 'global_data.websiteAuthInfo.versionCode') === PackageCode.HUNGRY_PANDA; // 是否熊猫店铺

  // 轮询查询 自提订单信息 / 卡券订单信息
  useEffect(() => {
    if (!isEmpty(orderInfo)) {
      setNativeOrderInfo(orderInfo);
      if ([ORDER_STATUS.WAIT_PAYMENT, ORDER_STATUS.PAYING, ORDER_STATUS.WAITING, ORDER_STATUS.CANCELED].includes(orderInfo?.status)) return;
      if (pollingFlag.current) return; // 防止重复轮询
      pollingFlag.current = true;
      const { pickupOrder, pickupAddress, ecardInfo } = orderInfo;
      if (isPickupOrder && isEmpty(pickupOrder) && isEmpty(pickupAddress)) {
        getPickupOrder(orderInfo);
        return;
      }
      if (isECardOrder && isEmpty(ecardInfo)) {
        getEcardInfo(orderInfo);
        return;
      }
    }
    () => {
      pollingFlag.current = false;
    }
  }, [orderInfo]);

  const {
    ecardList, // 券码
    canUsedEcardList, // 可用券码
  } = useMemo(() => {
    const ecardItems = get(nativeOrderInfo, 'ecardInfo.ecardItems', []);
    return {
      ecardList: ecardItems.filter?.((item) => item.verifyStatus!== ECARD_VERIFY_STATUS.none) || [],
      canUsedEcardList: ecardItems.filter?.((item) => item.verifyStatus === ECARD_VERIFY_STATUS.notVerify) || [],
    }
  }, [nativeOrderInfo?.ecardInfo]);

  /**
   * 是否展示券码
   * 订单待付款、待确认、支付中、待成团、已取消 -> 不展示
   */
   const visibleEcardCode = useMemo(() => {
    if ([ORDER_STATUS.WAIT_PAYMENT, ORDER_STATUS.UNCONFIRMED, ORDER_STATUS.PAYING, ORDER_STATUS.WAITING, ORDER_STATUS.CANCELED].includes(nativeOrderInfo?.status)) return false;
    return true;
  }, [nativeOrderInfo?.status]);

  /**
   * 是否展示二维码
   * (待使用 || 退款中(未使用)) && !已退款
   */
  const visibleQrcode = useMemo(() => {
    const { status, ecardInfo } = nativeOrderInfo;
    return (
      [ORDER_STATUS.WAIT_FULLFILLMENBT, ORDER_STATUS.FULFILLED].includes(status) ||
      (ORDER_STATUS.REFUNDING === status && ECARD_VERIFY_STATUS.notVerify === ecardInfo?.verifyStatus)
    ) && ORDER_STATUS.REFUNDED !== status;
  }, [nativeOrderInfo?.status, nativeOrderInfo?.ecardInfo]);

  // 生成二维码
  useEffect(() => {
    const code = get(nativeOrderInfo, 'pickupOrder.pickupCode') || get(nativeOrderInfo, 'ecardInfo.ticketCode');
    if (code) {
      QRCode.toDataURL(code, {
        width: 300,
        height: 300,
        margin: 1,
      }).then((res) => {
        setQrcodeUrl(res);
      });
    }
  }, [nativeOrderInfo?.pickupOrder, nativeOrderInfo?.ecardInfo]);

  const getPickupOrder = useCallback((orderInfo) => {
    if (!pollingFlag.current) return;
    orderApi.getPickupOrder({ token: orderInfo.token }).then(res => {
      const pickupOrder = omit(res, 'pickupAddress');
      const pickupAddress = get(res, 'pickupAddress');
      if ((isEmpty(pickupOrder) && isEmpty(pickupAddress))) {
        setTimeout(() => {
          getPickupOrder(orderInfo);
        }, 2000);
      } else {
        pollingFlag.current = false;
        setNativeOrderInfo({
          ...orderInfo,
          pickupOrder: omit(res, 'pickupAddress'),
          pickupAddress: get(res, 'pickupAddress'),
        });
        refresh && refresh();
      }
    });
  }, [])

  const getEcardInfo = useCallback((orderInfo) => {
    if (!pollingFlag.current) return;
    orderApi.getEcardInfo({ token: orderInfo.token }).then(res => {
      if (isEmpty(res)) {
        setTimeout(() => {
          getEcardInfo(orderInfo);
        }, 1000);
      } else {
        pollingFlag.current = false;
        setNativeOrderInfo({
          ...orderInfo,
          ecardInfo: res,
        });
        refresh && refresh();
      }
    });
  }, []);

  return {
    orderInfo: nativeOrderInfo,
    isPickupOrder,
    isAppointmentOrder,
    isECardOrder,
    visibleEcardCode,
    visibleQrcode,
    qrcodeUrl,
    ecardList,
    canUsedEcardList,
    isPandaVersion,
    pollingFlag,
  }
}