// 结算信息类型
export enum CHECKOUT_RULE_TYPE {
  file = 'file',
  image = 'image',
  text = 'text',
  idcard = 'id_card',
}

// 结算信息 内部组件类型
export enum TEMPLATE_TYPE {
  text = 'text',
  image = 'image',
  file = 'file',
}

// 自定义结算身份证信息 key
export enum CustomizedCheckoutIDCardKeyEnum {
  ID_CARD_IS_FRONT = 'ID_CARD_IS_FRONT',
  ID_CARD_IS_REVERSE = 'ID_CARD_IS_REVERSE',
  NUMBER_OF_IMAGES = 'NUMBER_OF_IMAGES',
  FILE_NAME = 'FILE_NAME',
}

export enum GoodsRangeEnum {
  all = 'all',
  products = 'products',
  collections = 'collections',
}

export enum CountryRangeEnum {
  all = 'all',
  countries = 'countries',
}