import Styled from 'styled-components';

export default Styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .ecard-code-inner {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }
  .ecard-code-content {
    .ecard-code-title {
      font-size: 14px;
      line-height: 20px;
      color: var(--color_body_text);
      font-weight: 500;
      margin-bottom: 4px;
    }
    .ecard-code-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .ecard-code-item {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 17px;
        color: rgba(var(--color_body_text_rgb), 0.6);
        .status-light {
          color: var(--color_body_text);
          font-weight: 500;
        }
      }
      .ecard-code-num {
        display: flex;
        align-items: center;
        gap: 4px;
        svg {
          width: 12px;
          height: 12px;
          fill: rgba(var(--color_body_text_rgb), 0.4);
          cursor: pointer;
        }
      }
    }
  }
`;
