import styled from 'styled-components';

export default styled.div`
  .tax-detail-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(var(--color_body_text_rgb), 0.08);
  }
  .tax-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__label {
      color: rgba(var(--color_body_text_rgb), 0.8);
    }
    &__value {
      color: var(--color_body_text_rgb);
    }
  }

  .tax-free-customer {
    text-align: right;
    color: rgba(var(--color_body_text_rgb), 0.6);
    margin-top: 8px;
  }
`;
