import React, { useEffect, useState } from 'react';
import { FormControl, FormError, Form, Radio, LayoutRow as Row, LayoutCol as Col, LayoutGrid as Grid } from 'zent';
import Style from './style';
import { FormattedMessage } from 'react-intl';
import { buriedPoint } from 'utils/log-buried-point';
import { intlLiToYuan } from 'utils/money';
import cn from 'classnames';

const RadioGroup = Radio.Group;

const MAX_SHOW = 5;

const DeliverySelect = (props) => {
  const {
    list = [],
    getSelectValue,
    defaultValue = '',
    name,
    validators = [],
    required = false,
    currency = 'USN',
    showCost = false,
    disabled = false,
  } = props;
  const multiCurrencyInfo = window.global_data?.multiCurrencyInfo;

  const field = Form.useField(name, defaultValue, [...validators]);
  const onChange = (event) => {
    event.stopPropagation();
    const delivery = list?.find((item) => item.value === event.target.value) || {};
    getSelectValue && getSelectValue(event.target.value, delivery);
    field.value = event.target.value;
    buriedPoint('checkout_seleteddelivery');
  };

  useEffect(() => {
    field.validate();
  }, [field?.value]);

  const listLen = list?.length || 0;
  const hasMore = listLen > MAX_SHOW;
  const firstItem = list?.[0] || {};

  const [showMore, setShowMore] = useState(false);

  return (
    <Style>
      <div className='select-container'>
        <FormControl required={required} withoutLabel>
          <Grid>
            {listLen > 1 ? (
              <RadioGroup className='pay-way-radio' onChange={onChange} value={field.value}>
                {list?.slice(0, MAX_SHOW)?.map((item, index) => (
                  <Row className='send-row' key={index}>
                    <Col span={18}>
                      <div
                        className={cn('payment-line', {
                          'logo-position-fix': false,
                        })}
                      >
                        <Radio className='pay-radio' value={item.value} disabled={!!item.disabled || disabled}>
                          <div className={cn({ 'item-wrapper': showCost })}>
                            <div className='delivery-content'>
                              <div className='radio-content-wrapper'>
                                <div className='pay-wrapper'>
                                  <div className='pay-text-wrapper'>
                                    <span className='pay-text'> {item?.text} </span>
                                    {((item.disabled && !!item?.disabledMsg) || !!item?.description) && (
                                      <span className='pay-description'>
                                        {item.disabled ? item?.disabledMsg || '' : item?.description || ''}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {showCost && (
                              <>
                                {item?.disabled ? (
                                  <span className='layout-cell-right'>--</span>
                                ) : (
                                  <div className='layout-cell-right'>
                                    {item.cost ? (
                                      intlLiToYuan({
                                        currency,
                                        value: item.cost,
                                        multiCurrencyInfo,
                                      })
                                    ) : (
                                      <FormattedMessage id='order.send.free' />
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </Radio>
                      </div>
                    </Col>
                  </Row>
                ))}
                {showMore &&
                  list?.slice(MAX_SHOW, listLen)?.map((item, index) => (
                    <Row className='send-row' key={index}>
                      <Col span={18}>
                        <div
                          className={cn('payment-line', {
                            'logo-position-fix': false,
                          })}
                        >
                          <Radio className='pay-radio' value={item.value} disabled={!!item.disabled || disabled}>
                            <div className={cn({ 'item-wrapper': showCost })}>
                              <div className='delivery-content'>
                                <div className='radio-content-wrapper'>
                                  <div className='pay-wrapper'>
                                    <div className='pay-text-wrapper'>
                                      <span className='pay-text'> {item?.text} </span>
                                      {((item.disabled && !!item?.disabledMsg) || !!item?.description) && (
                                        <span className='pay-description'>
                                          {item.disabled ? item?.disabledMsg || '' : item?.description || ''}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {showCost && (
                                <>
                                  {item?.disabled ? (
                                    <span className='layout-cell-right'>--</span>
                                  ) : (
                                    <div className='layout-cell-right'>
                                      {item.cost ? (
                                        intlLiToYuan({
                                          currency,
                                          value: item.cost,
                                          multiCurrencyInfo,
                                        })
                                      ) : (
                                        <FormattedMessage id='order.send.free' />
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </Radio>
                        </div>
                      </Col>
                    </Row>
                  ))}
                {hasMore && (
                  <>
                    {!showMore ? (
                      <div className='delivery-show-more' onClick={() => setShowMore(true)}>
                        <FormattedMessage id='general.load_more' defaultMessage='查看更多' />
                        <svg aria-hidden='true' width='16' height='16'>
                          <use xlinkHref='#iconic-xiala1' />
                        </svg>
                      </div>
                    ) : (
                      <div className='delivery-show-more' onClick={() => setShowMore(false)}>
                        <FormattedMessage id='put_away' defaultMessage='收起' />
                        <svg className=' icon-up' aria-hidden='true' width='16' height='16'>
                          <use xlinkHref='#iconic-xiala1' />
                        </svg>
                      </div>
                    )}
                  </>
                )}
              </RadioGroup>
            ) : (
              <Row className='send-row'>
                <Col span={18}>
                  <div
                    className={cn('payment-line', {
                      'logo-position-fix': false,
                    })}
                  >
                    <div
                      className={cn({
                        'item-wrapper': showCost,
                        'item-wrapper_disabled': firstItem?.disabled,
                      })}
                    >
                      <div className='delivery-text-wrapper'>
                        <span className='delivery-text skiptranslate'> {firstItem?.text} </span>
                        {firstItem?.description && (
                          <span className='delivery-description'>{firstItem?.description}</span>
                        )}
                      </div>

                      {showCost && (
                        <>
                          {firstItem?.disabled ? (
                            <div className='layout-cell-right'>--</div>
                          ) : (
                            <div className='layout-cell-right'>
                              {firstItem.cost ? (
                                intlLiToYuan({
                                  currency,
                                  value: firstItem.cost,
                                  multiCurrencyInfo,
                                })
                              ) : (
                                <FormattedMessage id='order.send.free' />
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Grid>
        </FormControl>
      </div>

      <FormError style={{ paddingBottom: field.error?.message ? 24 : 0 }}>{field.error?.message}</FormError>
    </Style>
  );
};

export default DeliverySelect;
