import React, { useContext, useMemo } from 'react';
import { useI18n } from 'hook/intl';
import { intlLiToYuan } from 'utils/money';
import { useIntl } from 'react-intl';
import { useRootContext } from '../../../../../context/root';
import { ContextOrder } from '../../../../../context/context-order';
import { PROMOTION_DESCRIPTIONS_TYPE, PROMOTION_DESCRIPTIONS_SUBTYPE } from '../../../../const';
import cn from 'classnames';
import PromotionDescriptions from '../../promotion-descriptions';
import PointsSelector from '../points-selector';
import { AdditionalFeeTotal, TotalItemPageType } from 'cpn/additional-fee-total';
import openTaxDetailModal from '../../../../../components/open-tax-detail-dialog';
import { Icon } from 'components/base';

export enum OrderTotalDetailsType {
  order = 'order',
  complete = 'complete',
}

interface IOrderTotalDetailsProps {
  type?: OrderTotalDetailsType;
  orderDetail: Record<string, any>;
  isPointsExchange: boolean;
  needShip: boolean;
  isPickup: boolean;
}

const OrderTotalDetails = ({
  type = OrderTotalDetailsType.order,
  orderDetail = {},
  isPointsExchange = false,
  needShip,
  isPickup,
}: IOrderTotalDetailsProps) => {
  const intl = useIntl();
  const { $fm } = useI18n(intl);
  const isComplete = type === OrderTotalDetailsType.complete;
  const { initialData = {}, theme } = useRootContext();

  const orderContext = useContext<Record<string, any>>(ContextOrder);

  const {
    priceInfo = {},
    discountInfo = {},
    checkoutPreview,
    currency,
    orderInfo = {},
    deliveryInfo = {},
    pointsInfo = {},
    additionalFees,
  } = { ...orderDetail, ...orderContext };

  const includeTaxPrice = orderInfo?.includeTaxPrice || orderDetail?.includeTaxPrice || 0;
  const { multiCurrencyInfo } = initialData;
  const language = initialData?.shopSetting?.language;
  const { taxInfo = {}, promotionDescriptions = [] } = orderDetail;
  const { freeTaxPrice = 0, totalTaxPrice = 0, freeTax = 0 } = taxInfo;

  /** 是否展示税费信息
   * 1. 免税金额存在，展示
   * 2. 不是含税价，且税费金额存在，展示
   */
  const showTaxInfo = !!freeTaxPrice || (!includeTaxPrice && totalTaxPrice > 0);
  const isIncludeTaxAndFreeTaxPrice = !!includeTaxPrice && freeTaxPrice > 0; // 含税价并且是免税用户

  const showBottomIncludeTax = !!includeTaxPrice && (freeTax ? freeTaxPrice > 0 : totalTaxPrice > 0); // 展示总计底部含税价

  const { totalPrice = 0, originalTotalLineItemsPrice = 0, originalTotalShippingPrice = 0 } = isComplete
    ? orderDetail
    : priceInfo;

  const shippingPrice = originalTotalShippingPrice;
  const showShipping = isComplete ? !!shippingPrice : needShip && !isPickup;

  // 运费文案
  const getShippingText = useMemo(() => {
    if (isComplete) {
      return intlLiToYuan({
        currency,
        value: shippingPrice,
      });
    }

    const price = shippingPrice;
    const hasShippingList = Boolean(deliveryInfo?.shippingList?.length);
    const isFreeShipping = discountInfo.freeShippingSuccess && success;

    let extraText;
    if (!hasShippingList) {
      extraText = intl.formatMessage({
        id: 'no_deliver',
        defaultMessage: '暂无物流',
      });
    } else if (price === 0) {
      extraText = intl.formatMessage({
        id: 'code_free_shipping',
        defaultMessage: '免运费',
      });
    } else {
      extraText = intlLiToYuan({
        currency,
        value: price || 0,
      });
    }

    if (isFreeShipping) {
      extraText = intl.formatMessage({
        id: 'setting.logistics_free',
        defaultMessage: '免费',
      });
    }

    return extraText;
  }, [isComplete, shippingPrice, deliveryInfo, discountInfo, currency]);

  return (
    <>
      {/* 商品金额  */}
      <div className='item-block' id='little-total'>
        <div className='sum-wrapper'>
          <div className='f-js-ac item-block-item'>
            <span className='main-title'>{$fm('70fb5b2cecdb46ebb9dd0baec3a7d949')}</span>
            <span className='sub-title total-goods-price'>
              {intlLiToYuan({
                currency,
                value: originalTotalLineItemsPrice,
              })}
            </span>
          </div>
          {promotionDescriptions.map((item, index) => {
            /** 折扣类的集合在下面展示 */
            if (item.type === PROMOTION_DESCRIPTIONS_TYPE.discount) return null;
            /** 积分单独做了一个样式，有交互，所以也单独展示 */
            if (
              !isPointsExchange &&
              item.type === PROMOTION_DESCRIPTIONS_TYPE.member &&
              item.subType === PROMOTION_DESCRIPTIONS_SUBTYPE.points_deduct
            )
              return null;
            let isSpecial = false;

            if (isComplete) {
              /** 改价，需要前端翻译，后端这块逻辑没有做翻译 */
              if (
                item.type === PROMOTION_DESCRIPTIONS_TYPE.edit_price &&
                item.subType === PROMOTION_DESCRIPTIONS_SUBTYPE.edit_goods_item
              ) {
                item.title = $fm('b32911a4e86c4e31a49edd755f0aef3d', '改价');
                isSpecial = true;
              }
            }
            return (
              <div className='f-js-ac item-block-item' key={index}>
                <span className='main-title'>{item?.title || ''}</span>
                <span className={cn('sub-title total-goods-price', { 'activity-label-price': isSpecial })}>
                  -
                  {intlLiToYuan({
                    currency,
                    value: item?.promotionAmount,
                  })}
                </span>
              </div>
            );
          })}
          {/* 多个自动折扣的合集 */}
          <PromotionDescriptions
            currency={currency}
            language={language}
            promotionDescriptions={promotionDescriptions}
          />
          {/* 积分 积分商品兑换不展示 */}
          {!isPointsExchange && (
            <PointsSelector
              pointsInfo={pointsInfo}
              currency={currency}
              intl={intl}
              theme={theme}
              checkoutPreview={checkoutPreview}
              canEdit={!orderInfo?.isTriggeredPay}
            />
          )}
          {/* 运费 */}
          {showShipping && (
            <div className='f-js-ac item-block-item'>
              <span className='main-title'>{$fm('order.address.total_ship')}</span>
              <div className='layout-cell-right font-light shipping-price'>{getShippingText}</div>
            </div>
          )}
          {/* 附加费 */}
          <AdditionalFeeTotal
            $fm={$fm}
            additionalFees={additionalFees}
            currency={currency}
            pageType={isComplete ? TotalItemPageType.ORDER_DETAIL : undefined}
          />
          {/* 税费 */}
          {showTaxInfo && (
            <div className='f-js-ac item-block-item'>
              <span className='main-title'>{$fm('order.tax.discount', '税费')}</span>
              <div
                className='sub-title total-goods-price finger'
                onClick={() => openTaxDetailModal({ intl, theme, taxInfo, currency, isIncludeTaxAndFreeTaxPrice })}
              >
                <span>
                  {isIncludeTaxAndFreeTaxPrice && '-'}
                  {intlLiToYuan({
                    currency,
                    value: isIncludeTaxAndFreeTaxPrice ? freeTaxPrice : totalTaxPrice,
                  })}
                </span>
                <Icon className='icon' name='iconic-xiala' size='small' />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='sum-count-wrapper skiptranslate'>
        {/* 总计 */}
        <div className='f-js-ac'>
          <div className='font-light'>{$fm('order.address.total', '总计')}</div>
          <div className='layout-cell-right font-light'>
            <div className='total-price'>
              {/* 这后面交给后端算吧 */}
              {intlLiToYuan({
                currency,
                value: totalPrice,
              })}
            </div>
          </div>
        </div>
        {/* 含税价 */}
        {showBottomIncludeTax && (
          <div className='layout-cell-right font-light'>
            <span
              className='include-tax'
              onClick={() => {
                if (freeTaxPrice) return;
                openTaxDetailModal({ intl, theme, taxInfo, currency, isIncludeTaxAndFreeTaxPrice });
              }}
              style={{ cursor: freeTaxPrice ? 'default' : 'pointer', display: 'inline-flex', alignItems: 'center' }}
            >
              <span className='font-light'>{$fm('2527a69c58444ff88617e73c92d5c8a3', '包含税')}</span>
              <span className='font-light'>
                {intlLiToYuan({
                  currency,
                  value: freeTax ? 0 : totalTaxPrice,
                })}
              </span>
              {!freeTaxPrice && <Icon className='icon' name='iconic-xiala' size='small' />}
            </span>
          </div>
        )}
        {/* 设置了多币种才展示这部分 */}
        {multiCurrencyInfo?.currency && (
          <div className='layout-cell-right font-light'>
            <div className='multi-currency-total-price'>
              {intlLiToYuan({
                currency,
                value: totalPrice,
                multiCurrencyInfo,
              })}
            </div>
            <div className='multi-currency-total-price-desc'>
              {intl.formatMessage({
                defaultMessage: '金额可能会因汇率而异',
                id: 'c03815036df94ee686046643ff35e65c',
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderTotalDetails;
