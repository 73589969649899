/**
 * com.youzan.i18n.discount.valueobject.rule.DiscountLimitQuotaRule
 */
 interface IDiscountLimitQuotaRule {
  /** 领取数量 */
  limitNum?: number;
  /** 限购频率 :       every_day : 每天       cycle : 活动周期 */
  limitFrequency?: string;
}

/**
 * com.youzan.i18n.discount.valueobject.DiscountGets
 */
interface IDiscountGets {
  /** 享受折扣的数量。买赠需要提示赠品的数量 */
  discountQuantity?: number;
  /** 折扣值类型：amount/percentage */
  type?: string;
  /** 折扣值：几折或者金额 */
  discountValue?: number;
}

/**
 * com.youzan.i18n.discount.valueobject.MinimumRequirement
 */
interface IMinimumRequirement {
  /** 最低要求值 */
  requireValue?: number;
  /** 最低要求类型：amount/quantity */
  type?: string;
}

/**
 * com.youzan.i18n.discount.valueobject.rule.DiscountPromotionRule
 */
interface IDiscountPromotionRule {
  /** 优惠结果 */
  discountGets?: IDiscountGets;
  /** 最低要求 */
  minimumRequirement?: IMinimumRequirement;
}

/**
 * com.youzan.i18n.order.shared.vo.OrderTaxVO.OrderTaxDetailVO 
 */
 interface IOrderTaxDetailVO {
  /** 税率 */
  taxRate?: number
  /** 税费 */
  taxPrice?: number
  /** 税费名称 */
  taxName?: string
}

/**
 * com.youzan.i18n.order.shared.vo.OrderTaxVO 
 */
export interface IOrderTaxVO {
  /** 运费税详细信息 */
  shippingTaxDetail: IOrderTaxDetailVO
  /** 运费税详细信息 */
  shippingTaxDetails: IOrderTaxDetailVO[]
  /** 总税费 */
  totalTaxPrice: number
  /** 消费税详细信息 */
  salesTaxDetails: IOrderTaxDetailVO[]
}

export interface ITotalDiscountList {
  /** 限购规则 */
  limitQuotaRule?: IDiscountLimitQuotaRule;
  /** 折扣码 */
  discountTitle?: string;
  /** 折扣码 */
  discountCode?: string;
  /** 阶梯优惠规则集 */
  promotionRules?: IDiscountPromotionRule[];
  /** 折扣 */
  discountGets?: IDiscountGets;
  /** 折扣金额 */
  totalDiscountAmount?: number;
  /** 营销工具类型 */
  umpType?: string;
  /** 折扣模式 */
  discountMode?: string;
  /** 折扣类型 */
  discountType?: string;
  /** 折扣应用状态；  1：已满足折扣，2：提示去凑单，3：提示选赠品 */
  applyDiscountStatus?: number;
  promotionId?: number;
  /** 最低要求 */
  minimumRequirement?: IMinimumRequirement;
}

export enum EnumPromotionDescriptionType {
  /** 折扣 */
  discount = 'discount',
  /** 会员 */
  member ='member',
  /** 拼团 */
  group ='group',
  /** 改价 */
  editPrice ='edit_price',
}


export enum EnumPromotionDescriptionSubType {
  /** 会员价 */
  memberPrice = "member_price",
  /** 积分抵扣 */
  pointsDeduct = "points_deduct",
  /** 积分兑换 */
  pointsExchange = "points_exchange",
  /** 通用码 */
  discountCode = "discountCode",
  /** 自动折扣 */
  automaticDiscount = "automaticDiscount",
  /** 一卡一码 */
  cardCode = "cardCode",
  /** 商品改价 */
  editGoodsItem = "edit_goods_item",
  /** 拼团 */
  group = "group",
}

export enum EnumPromotionDescriptionLeafType {
  /** 百分比 */
  percentage = 'percentage',
  /** 固定金额 */
  fixedAmount = 'fixedAmount',
  /** 买X送Y */
  bxgy = 'bxgy',
  /** 免运费 */
  freeShipping = 'freeShipping',
}

export interface IPromotionDescription {
  /** 折扣 */
  discountGets?: IDiscountGets
  /** 优惠子类  如折扣相关：  <p>  - percentage：百分比，  - fixedAmount：固定金额，  - bxgy：买X送Y  - freeShipping：免运费 */
  leafType?: EnumPromotionDescriptionLeafType
  /** 优惠小类（会员价、会员积分、自动折扣、通用码、一卡一码、普通拼团、阶梯拼团） */
  subType?: EnumPromotionDescriptionSubType
  /** 折扣模式，兼容字段，同 subType */
  discountMode?: string
  /** 折扣类型，兼容字段，同 leafType */
  discountType?: string
  /** 优惠标题（会员计划名称、折扣活动名称/折扣码、积分名称） */
  title?: string
  /** 优惠大类（会员相关、折扣相关、拼团相关）；  需要前端基于大类做分组，某一类的明细放一起展示 */
  type?: EnumPromotionDescriptionType
  /** 优惠金额，这里是每个小类的优惠明细汇总，因为每一个大类上需要有一个大类的金额汇总，需要前端在基于大类分组之后，做一下金额汇总即可。  比如：折扣优惠总金额60，其中自动折扣优惠40，一卡一码优惠20 */
  promotionAmount?: number
  /** 优惠ID（会员计划id、折扣活动id、拼团活动id） */
  promotionId?: number
  /** 最低要求 */
  minimumRequirement?: IMinimumRequirement
}

/**
 * com.youzan.i18n.order.shared.vo.AdditionalFeeVO.AdditionalFeeItemVO 
 */
 interface IAdditionalFeeItemVO {
  /** 附加费单价 */
  unitPrice: number
  /** 订单项数量 */
  quantity: number
  /** 该订单项附加费总金额 */
  totalPrice: number
  /** 订单项商品图片 */
  imageUrl: string
}

export enum AdditionalFeeSceneEnum {
  ORDER = 'order',
  GOODS = 'goods',
}

/**
 * com.youzan.i18n.order.shared.vo.AdditionalFeeVO 
 */
export interface IAdditionalFeeVO {
  /** 该类型附加费总金额 */
  totalPrice: number
  /** 附加费名称 */
  name: string
  /** 附加费类型 */
  type: string
  /** 附加费分类明细 */
  items: IAdditionalFeeItemVO[]
  /** 附加费场景：订单、商品  {@link  AdditionalFeeSceneEnum} */
  scene: AdditionalFeeSceneEnum
}

/**
 * com.youzan.i18n.order.shared.vo.AdditionalFeeSettingDescVO 
 */
export interface IAdditionalFeeSettingVO {
  /** 创建时间 */
  createdAt?: number;
  /** 店铺id */
  kdtId?: number;
  /** 是否需要收取附加费税费 */
  requiresTax?: number;
  /** 附加费名称 */
  name?: string;
  /** 附加费说明 */
  description?: string;
  /** 费用数值 */
  feeValue?: number;
  /** 附加费类型 */
  type?: string;
  /** 计费方式 */
  feeMethod?: string;
  /** 设置id */
  settingId?: number;
  /** 状态 */
  status?: string;
  /** 更新时间 */
  updatedAt?: number;
}

export enum AdditionalFeeSettingTypeEnum {
  ORDER = 'order',
  GOODS_PHYSICAL = 'goods_physical',
  GOODS_VIRTUAL = 'goods_virtual',
  GOODS_SERVICE = 'goods_service',
}
